import { Badge, IconButton, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { Settings } from "react-feather";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

function NotificationsDropdown() {
  const ref = useRef(null);
  const { user } = useSelector((state) => state.authReducer);
  const history = useHistory();

  const handleOpen = () => {
    history.push("/empresa");
  };

  const Indicator = styled(Badge)`
    .MuiBadge-badge {
      background: ${(props) =>
        user.faturaVencida && user.faturaVencida > 0
          ? "red"
          : props.theme.header.indicator.background};
      color: ${(props) => props.theme.palette.common.white};
    }
  `;

  return (
    <React.Fragment>
      {(user.profile.papel === "SUPORTE" ||
        user.profile.papel === "RECRUTAMENTO" ||
        user.profile.papel === "ADMINISTRADORA") && (
        <Tooltip title="Minha Empresa">
          <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
            <Indicator badgeContent={user.faturasEmAberto}>
              <Settings />
            </Indicator>
          </IconButton>
        </Tooltip>
      )}
    </React.Fragment>
  );
}

export default NotificationsDropdown;
