import React from "react";
import { Helmet } from "react-helmet";

import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";


import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import jssPreset from '@mui/styles/jssPreset';
import * as Yup from "yup";
import Alert from "./components/alert/Alert";
import AlertComponent from "./components/alert/AlertComponent";
import Routes from "./routes/Routes";
import createTheme from "./theme";
import { useSelector } from "react-redux";


Yup.date().default(null);

Yup.setLocale({
  mixed: {
    default: "é inválido",
    required: "é um campo obrigatório",
    oneOf: "deve ser um dos seguintes valores: ${values}",
    notOneOf: "não pode ser um dos seguintes valores: ${values}",
  },
  string: {
    length: "deve ter exatamente ${length} caracteres",
    min: "deve ter pelo menos ${min} caracteres",
    max: "deve ter no máximo ${max} caracteres",
    email: "tem o formato de e-mail inválido",
    url: "deve ter um formato de URL válida",
    trim: "não deve conter espaços no início ou no fim.",
    lowercase: "deve estar em maiúsculo",
    uppercase: "deve estar em minúsculo",
    matches: "somente números são permitidos",
  },
  number: {
    min: "deve ser no mínimo ${min}",
    max: "deve ser no máximo ${max}",
    lessThan: "deve ser menor que ${less}",
    moreThan: "deve ser maior que ${more}",
    notEqual: "não pode ser igual à ${notEqual}",
    positive: "deve ser um número posítivo",
    negative: "deve ser um número negativo",
    integer: "deve ser um número inteiro",
  },
  date: {
    min: "deve ser maior que a data ${min}",
    max: "deve ser menor que a data ${max}",
    typeError: "formato de data inválida",
  },
  array: {
    min: "deve ter no mínimo ${min} itens",
    max: "deve ter no máximo ${max} itens",
  },
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {

  const { currentTheme } = useSelector((state) => state.themeReducer);

  const theme = createTheme(currentTheme);


  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Fictor"
        defaultTitle="Fictor"
      />
      <StylesProvider jss={jss}>
        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}

          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <Routes />
              <AlertComponent ref={(ref) => Alert.setAlertRef(ref)} />
            </ThemeProvider>
          </MuiThemeProvider>

        {/*</MuiPickersUtilsProvider>*/}
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
