
import styled from "@emotion/styled";
import { Alert, CssBaseline, Hidden, Paper as MuiPaper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Settings from "../components/Settings";

const drawerWidth = 258;

const Root = styled('div')(() => `
 display: flex;
  min-height: 100vh;
`,
);

const Drawer = styled.div`
  ${({theme}) => theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled('div')(() => `
  flex: 1;
  display: flex;
  flex-direction: column;
  
`,
);

const Paper = styled(MuiPaper)();

const MainContent = styled(Paper)(
  ({ theme }) => `
  flex: 1;
  padding: 15px;
  background: ${theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`
);



function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const Dashboard = ({ children, routes }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  const width = useWidth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />

      <Drawer>
        <Hidden only={['lg', 'xl', 'md']} implementation="js">
          
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          
        </Hidden>
        <Hidden only={['xs', 'sm']}  implementation="css">
          
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
          
        </Hidden>
      </Drawer>
      <AppContent>
        {user.messageSystem &&
          (!user.messageSystem.dataFim ||
            new Date(user.messageSystem.dataFim).getTime() >
            new Date().getTime()) && (
            <Alert style={{ margin: 3 }} variant="outlined" severity={user.messageSystem.severity}>
              <div
                dangerouslySetInnerHTML={{ __html: user.messageSystem.texto }}
              />
            </Alert>
          )}
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={width === 'xs' || width === 'sm' ? 12 : 5}>
          {children}
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  );
};

export default Dashboard;
