//import axios from "../utils/axios";
import Axios from "axios";

function buscarPessoaUsuarioLogado() {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorId(id) {
  return new Promise((resolve, reject) => {
    Axios
      .get(`/pessoa/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updatePessoaUsuarioLogado({
  email,
  telefone,
  nome,
  dataNascimento,
  bibliografia,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      email,
      telefone,
      nome,
      dataNascimento,
      bibliografia,
    };
    Axios.put(`/pessoa`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorDocumento(cpf) {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa/buscarPorDocumento/${cpf}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



function buscarArvore() {
  return new Promise((resolve, reject) => {
    Axios.post(`/pessoa/arvore`, null)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarHierarquia() {
  return new Promise((resolve, reject) => {
    Axios.post(`/pessoa/hierarquia`, null)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function save({
  nascimentoRepresentante,
  idPessoa,
  faixaProducao,
  documento,
  geraCnab,
  nacionalidade,
  profissao,
  email,
  emailAlternativo,
  rg,
  ufRG,
  unidadePagamento,
  rgOrgao,
  telefone,
  status,
  tipoPessoa,
  sexo,
  nome,
  razaoSocial,
  estadoCivil,
  dataNascimento,
  dataEfetivacao,
  dataDesligamento,
  superior,
  geraComissao,
  unidadeOperacao,
  classe,
  documentoRepresentante,
  ignoraFaixaProducao,
  nomeRepresentante,
  atuacao }) {
  return new Promise((resolve, reject) => {

    var vo = {
      nascimentoRepresentante,
      idPessoa,
      documento,
      nacionalidade,
      profissao,
      geraComissao,
      ignoraFaixaProducao,
      email, documentoRepresentante, nomeRepresentante,
      unidadePagamento,
      emailAlternativo,
      rg,
      ufRG,
      faixaProducao,
      rgOrgao,
      telefone,
      tipoPessoa,
      sexo,
      geraCnab,
      nome,
      razaoSocial,
      estadoCivil,
      dataNascimento,
      dataEfetivacao,
      dataDesligamento,
      superior,
      unidadeOperacao,
      status,
      classe,
      atuacao
    }

    Axios
      .post(`/pessoa/`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function pesquisar(documento, email, nomeRepresentante, nome, razaoSocial, page = 0) {
  return new Promise((resolve, reject) => {
    var vo = {
      documento: documento,
      email: email,
      nomeRepresentante: nomeRepresentante,
      nome: nome,
      razaoSocial: razaoSocial,
    };

    Axios.post(`/pessoa/pesquisa/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const PessoaService = {
  buscarPessoaUsuarioLogado,
  updatePessoaUsuarioLogado,
  buscarHierarquia,
  buscarPorDocumento,
  pesquisar,
  buscarPorId,
  buscarArvore,
  save
};

export default PessoaService;
