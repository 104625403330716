import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Typography, Grid } from "@mui/material";
const FieldSet = (props) => {
  return (
    <>
      {props.hasbackground ? <Grid sx={{ backgroundColor: '#f5f5f5', padding:'14px' }}><Typography variant="h6">{props.title}</Typography>
        <Box my={2} />
        <Divider />
        <Box my={5} />

        {props.children}</Grid> : <><Typography variant="h6">{props.title}</Typography>
        <Box my={2} />
        <Divider />
        <Box my={5} />
        {props.children}</>}

    </>
  );
};
FieldSet.propTypes = {
  title: PropTypes.string.isRequired,
};
export default FieldSet;
